import jwtDecode from "jwt-decode";
import { SET_OPEN_MENU } from "../../actions/global";
import { SET_AUTHENTICATED, LOGIN } from "../../actions/account";

const initialState = {
  isOpenedMenu: false,
};

function global(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_MENU:
      return {
        ...state,
        isOpenedMenu: action.payload,
        isLogin: action.payload,
      };
    case SET_AUTHENTICATED:
      const { authToken, isLogin } = action.payload;
      const decoded = jwtDecode(authToken);
      return {
        ...state,
        authToken: authToken,
        expiredTime: decoded.exp,
      };
    case LOGIN: {
      const {
        loginResult: { authToken, idToken },
        isLogin,
      } = action.payload;
      const decoded = jwtDecode(authToken);

      return {
        ...state,
        authToken: authToken,
        idToken: idToken,
        expiredTime: decoded.exp,
      };
    }
    default:
      return state;
  }
}

export default global;
